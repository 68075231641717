module directives {
    export module master {
        interface IAuthDashboardScope extends ng.IScope {
            load();
            loadDashboard();
            viewDashboardItem(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadConsignment(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadSalesOrder(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadPurchaseOrder(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadPayment(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadLetterOfCredit(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            loadCostInvoice(dashboardItem: interfaces.master.IAuthorisationDashBoard);
            refreshDocuments(showall:boolean,dashboardItem: interfaces.master.IAuthorisationDashBoard);
            searchChanged();
            changeStatus(newStatusId: number, comments: string, dashboardItem: interfaces.master.IAuthorisationDashBoard);
            changeConsignmentStatus(paramsObject: interfaces.master.IStatusChange);
            changePurchaseOrderStatus(paramsObject: interfaces.master.IStatusChange);
            changeSalesOrderStatus(paramsObject: interfaces.master.IStatusChange);
            changePaymentStatus(paramsObject: interfaces.master.IStatusChange);
            changeLetterOfCreditStatus(paramsObject: interfaces.master.IStatusChange);
            changeCostInvoiceStatus(paramsObject: interfaces.master.IStatusChange);
            authorisationDashboard: Array<interfaces.master.IAuthorisationDashBoard>;
            authorisationDashboardOrginal: Array<interfaces.master.IAuthorisationDashBoard>;
            searchText: string;
            showAll: boolean;
        }

        export class authDashboardDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/master/authDashboardControlView.html';
            scope = {
                authorisationDashboard : "=?"
            }


            messages: interfaces.applicationcore.IMessage[];
            authHook: any;
            total: number = 0; //generic var use for summing totals

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private statusService: interfaces.master.IStatusService,
                private salesOrderService: interfaces.sales.ISalesOrderService,
                private paymentService: interfaces.payment.IPaymentService,
                private purchaseOrderService: interfaces.purchase.IPurchaseOrderService,
                private consignmentService: interfaces.consignment.IConsignmentService,
                public letterOfCreditService: services.lc.letterOfCreditService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $q: ng.IQService,
                private $transitions: ng.ui.core.ITransition,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService
            ) {
            }

            link = ($scope: IAuthDashboardScope, $element: ng.IAugmentedJQuery, $state) => {
                $scope.load = () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'authDashboard.items'
                    }, () => {
                        return $scope.loadDashboard();
                    });
                };

                $scope.loadDashboard = () => {
                    return this.statusService.getAuthorisationDashBoard().query({}, (data: ng.resource.IResourceArray<interfaces.master.IAuthorisationDashBoard>) => {
                        $scope.authorisationDashboard = data;
                        $scope.authorisationDashboardOrginal = data;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                };

                $scope.viewDashboardItem = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    if (dashboardItem.ShowDetail) { //Currently ticked, so hide/
                        dashboardItem.ShowDetail = false;
                    }
                    else {
                        switch (dashboardItem.TypeClassificationValueId) {
                            case 348:  //Letter of Credit
                                if (!dashboardItem.LetterOfCredit) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                        return $scope.loadLetterOfCredit(dashboardItem);
                                    });
                                }
                                break;                            
                            case 349:  //Purchase Order
                                if (!dashboardItem.PurchaseOrder) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                        return $scope.loadPurchaseOrder(dashboardItem);
                                    });
                                }
                                break;
                            case 371: //Import Consignment
                            case 433: //Export Consignment
                                if (!dashboardItem.Consignment) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                        return $scope.loadConsignment(dashboardItem);
                                    });
                                }
                                break;
                            case 410: //Cost Invoice
                                if (!dashboardItem.CostInvoice) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                        return $scope.loadCostInvoice(dashboardItem);
                                    });
                                }
                                break;                                
                            case 465:  //Sales Order
                                if (!dashboardItem.SalesOrder) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                            return $scope.loadSalesOrder(dashboardItem);
                                    });
                                }
                                break;
                            case 590:   //Customer Payment
                            case 511:  //Supplier Payment
                                if (!dashboardItem.Payment) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'authDashboard.items'
                                    }, () => {
                                        return $scope.loadPayment(dashboardItem);
                                    });
                                }
                                break;
                        }

                        _.map($scope.authorisationDashboard, function (x) {
                            x.ShowDetail = false;
                            return x
                        });
                        dashboardItem.ShowDetail = true;
                    }
                };

                $scope.changeStatus = (newStatusId: number, comments: string, dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    var paramsObject: interfaces.master.IStatusChange = {
                        referenceId: dashboardItem.ReferenceId,
                        newStatusId: newStatusId,
                        comments: comments
                    };

                    switch (dashboardItem.TypeClassificationValueId) {
                        case 348:  //Letter  of Credit
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                return $scope.changeLetterOfCreditStatus(paramsObject);
                            });
                            break;                        
                        case 349:  //Purchase Order
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                return $scope.changePurchaseOrderStatus(paramsObject);
                            });
                            break;
                        case 371: //Import Consignment
                        case 433: //Export Consignment
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                return $scope.changeConsignmentStatus(paramsObject);
                            });
                            break;
                        case 410: //Cost Invoice
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                return $scope.changeCostInvoiceStatus(paramsObject);
                            });
                            break;                            
                        case 465:  //Sales Order
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                    return $scope.changeSalesOrderStatus(paramsObject);
                            });
                            break;
                        case 590:  //customer payment
                        case 511:  //Supplier Payment
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'authDashboard.items'
                            }, () => {
                                    return $scope.changePaymentStatus(paramsObject);
                            });
                            break;
                    }
                }

                $scope.changeLetterOfCreditStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.letterOfCreditService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.changeConsignmentStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.consignmentService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.changeCostInvoiceStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.commercialInvoiceService.changeStatus(paramsObject.referenceId,paramsObject.newStatusId, 1,paramsObject.comments).save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.changePurchaseOrderStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.purchaseOrderService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.changeSalesOrderStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.salesOrderService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                }

                $scope.changePaymentStatus = (paramsObject: interfaces.master.IStatusChange) => {
                    return this.paymentService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);
                        $scope.loadDashboard();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.refreshDocuments = (showAll:boolean,dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    $scope.showAll = showAll;
                    dashboardItem.documentRepository = null;

                    var referenceNumber: string = "";
                    var systemType: Enum.EnumSystemType;
                    var ownerEntityId: number = 0;

                    //Map Reference Number and Owner ENtity based on Type
                    switch (dashboardItem.TypeClassificationValueId) {
                        case 348:  //Letter  of Credit
                            referenceNumber = dashboardItem.LetterOfCredit.Code
                            systemType = Enum.EnumSystemType.LetterOfCredit;
                            ownerEntityId = dashboardItem.LetterOfCredit.EntityId;
                            break;                        
                        case 349:  //Purchase Order
                            referenceNumber = dashboardItem.PurchaseOrder.OrderNumber;
                            systemType = Enum.EnumSystemType.PurchaseOrder;
                            ownerEntityId = dashboardItem.PurchaseOrder.OwnerEntityId
                            break;
                        case 371: //Import Consignment
                            referenceNumber = dashboardItem.Consignment.WaybillNumber;
                            systemType = Enum.EnumSystemType.ImportConsignment;
                            ownerEntityId = dashboardItem.Consignment.OwnerEntityId
                            break;
                        case 433: //Export Consignment
                            referenceNumber = dashboardItem.Consignment.WaybillNumber;
                            systemType = Enum.EnumSystemType.Consignment;
                            ownerEntityId = dashboardItem.Consignment.OwnerEntityId
                            break;
                        case 465:  //Sales Order
                            referenceNumber = dashboardItem.SalesOrder.OrderNumber;
                            systemType = Enum.EnumSystemType.SalesOrder;
                            ownerEntityId = dashboardItem.SalesOrder.OwnerEntityId
                            break;
                        case 590:  //Customer Payments
                            referenceNumber = dashboardItem.Payment.PaymentCode;
                            systemType = Enum.EnumSystemType.CustomerPayments;
                            ownerEntityId = dashboardItem.Payment.OwnerEntityId
                            break;
                        case 511:  //Supplier Payments
                            referenceNumber = dashboardItem.Payment.PaymentCode;
                            systemType = Enum.EnumSystemType.SupplierPayment;
                            ownerEntityId = dashboardItem.Payment.OwnerEntityId
                            break;
                        case 410:  //Cost Invoices
                            referenceNumber = dashboardItem.CostInvoice.CostInvoiceTransactionNumber; //this will either be the Consignment Number or the Manifest Number

                            if (dashboardItem.CostInvoice.ConsignmentId){
                                if(dashboardItem.CostInvoice.IsInbound){
                                    systemType = Enum.EnumSystemType.ImportConsignment;
                                }
                                else{
                                    systemType = Enum.EnumSystemType.Consignment;
                                }
                            }
                            else{
                                systemType = Enum.EnumSystemType.Manifest;
                            }     

                            ownerEntityId = dashboardItem.CostInvoice.OwnerEntity.Id
                            break;                            
                    }


                    return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: referenceNumber, systemType: systemType, ownerEntityId: ownerEntityId, showAll:showAll?true:false}, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                        dashboardItem.documentRepository = documentRepository;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                }

                $scope.loadLetterOfCredit = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.letterOfCreditService.getLetterOfCredit(dashboardItem.ReferenceId).query({}, (data: interfaces.lc.ILetterOfCredit) => {
                            dashboardItem.LetterOfCredit = data;
                            dashboardItem.Status = {
                                StatusName: dashboardItem.LetterOfCredit.StatusName,
                                Id: dashboardItem.LetterOfCredit.Id,
                                EntityId: dashboardItem.LetterOfCredit.EntityId,
                                CurrentStatusId: dashboardItem.LetterOfCredit.StatusId,
                                CurrentStatusComments: dashboardItem.LetterOfCredit.StatusLastComments,
                                CurrentStatusStampDate: dashboardItem.LetterOfCredit.StatusLastUpdateStampDate,
                                CurrentStatusStampFullName: dashboardItem.LetterOfCredit.StatusLastUpdateStampFullName,
                                CurrentStatusType: Enum.EnumStatusType.LetterOfCredit,
                                EnumStatusType: Enum.EnumStatusType.LetterOfCredit,
                                EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.LetterOfCredit,
                                IsEditable: true
                            };
                            $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.loadSalesOrder = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.salesOrderService.getSalesOrder().query({
                        Id: dashboardItem.ReferenceId
                    }, (data: interfaces.sales.ISalesOrder) => {
                            dashboardItem.SalesOrder = data;
                            dashboardItem.Status = {
                                StatusName: dashboardItem.SalesOrder.StatusCode + ' - ' + dashboardItem.SalesOrder.StatusName,
                                Id: dashboardItem.SalesOrder.Id,
                                EntityId: dashboardItem.SalesOrder.OwnerEntityId,
                                CurrentStatusId: dashboardItem.SalesOrder.StatusId,
                                CurrentStatusComments: dashboardItem.SalesOrder.StatusLastComments,
                                CurrentStatusStampDate: dashboardItem.SalesOrder.StatusLastUpdateStampDate,
                                CurrentStatusStampFullName: dashboardItem.SalesOrder.StatusLastUpdateStampFullName,
                                CurrentStatusType: Enum.EnumStatusType.SalesOrder,
                                EnumStatusType: Enum.EnumStatusType.SalesOrder,
                                EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.SalesOrder,
                                IsEditable: true
                            };
                            $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.loadConsignment = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.consignmentService.GetConsignment(dashboardItem.ReferenceId).get({
                    }, (data: interfaces.consignment.IConsignment) => {
                        dashboardItem.Consignment = data;
                        dashboardItem.Status = {
                            StatusName: dashboardItem.Consignment.StatusName,
                            Id: dashboardItem.Consignment.Id,
                            EntityId: dashboardItem.Consignment.OwnerEntityId,
                            CurrentStatusId: dashboardItem.Consignment.StatusId,
                            CurrentStatusComments: dashboardItem.Consignment.StatusLastComments,
                            CurrentStatusStampDate: dashboardItem.Consignment.StatusLastUpdateStampDate,
                            CurrentStatusStampFullName: dashboardItem.Consignment.StatusLastUpdateStampFullName,
                            CurrentStatusType: (dashboardItem.Consignment.IsInbound) ? Enum.EnumStatusType.ConsignmentImport : Enum.EnumStatusType.ConsignmentExport,
                            EnumStatusType: (dashboardItem.Consignment.IsInbound) ? Enum.EnumStatusType.ConsignmentImport : Enum.EnumStatusType.ConsignmentExport,
                            EnumStatusAuthorisationType: (dashboardItem.Consignment.IsInbound) ? Enum.EnumStatusAuthorisationType.ConsignmentImport : Enum.EnumStatusAuthorisationType.ConsignmentExport,
                            IsEditable: true
                        };
                        $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.loadPurchaseOrder = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.purchaseOrderService.getPurchaseOrder().query({
                        Id: dashboardItem.ReferenceId
                    }, (data: interfaces.purchase.IPurchaseOrder) => {
                        dashboardItem.PurchaseOrder = data;
                        dashboardItem.Status = {
                            StatusName: dashboardItem.PurchaseOrder.StatusCode + ' - ' + dashboardItem.PurchaseOrder.StatusName,
                            Id: dashboardItem.PurchaseOrder.Id,
                            EntityId: dashboardItem.PurchaseOrder.OwnerEntityId,
                            CurrentStatusId: dashboardItem.PurchaseOrder.StatusId,
                            CurrentStatusComments: dashboardItem.PurchaseOrder.StatusLastComments,
                            CurrentStatusStampDate: dashboardItem.PurchaseOrder.StatusLastUpdateStampDate,
                            CurrentStatusStampFullName: dashboardItem.PurchaseOrder.StatusLastUpdateStampFullName,
                            CurrentStatusType: Enum.EnumStatusType.POStatus,
                            EnumStatusType: Enum.EnumStatusType.POStatus,
                            EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.PurchaseOrder,
                            IsEditable: true
                        };
                        $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.loadPayment = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.paymentService.getPayment().query({
                        Id: dashboardItem.ReferenceId
                    }, (data: interfaces.payment.IPayment) => {
                        dashboardItem.Payment = data;
                            dashboardItem.Status = {
                                StatusName: dashboardItem.Payment.StatusName,
                                Id: dashboardItem.Payment.Id,
                                EntityId: dashboardItem.Payment.OwnerEntityId,
                                CurrentStatusId: dashboardItem.Payment.StatusId,
                                CurrentStatusComments: dashboardItem.Payment.StatusLastComments,
                                CurrentStatusStampDate: dashboardItem.Payment.StatusLastUpdateStampDate,
                                CurrentStatusStampFullName: dashboardItem.Payment.StatusLastUpdateStampFullName,
                                CurrentStatusType: (dashboardItem.Payment.IsInbound) ? Enum.EnumStatusType.SupplierPayment : Enum.EnumStatusType.CustomerPayment,
                                EnumStatusType: (dashboardItem.Payment.IsInbound) ? Enum.EnumStatusType.SupplierPayment : Enum.EnumStatusType.CustomerPayment,
                                EnumStatusAuthorisationType: (dashboardItem.Payment.IsInbound) ? Enum.EnumStatusAuthorisationType.SupplierPayment : Enum.EnumStatusAuthorisationType.CustomerPayment,
                                IsEditable: true
                        };
                        $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.loadCostInvoice = (dashboardItem: interfaces.master.IAuthorisationDashBoard) => {
                    return this.commercialInvoiceService.get(dashboardItem.ReferenceId,null,null,true).get((data: interfaces.logistics.ICommercialInvoice) => {
                     dashboardItem.CostInvoice = data;

                     dashboardItem.Status = {
                        StatusName: dashboardItem.CostInvoice.CostInvoiceStatusDescription,
                        Id: dashboardItem.CostInvoice.Id,
                        EntityId: dashboardItem.CostInvoice.OwnerEntity.Id,
                        CurrentStatusId: dashboardItem.CostInvoice.CostInvoiceStatusId,
                        CurrentStatusComments: dashboardItem.CostInvoice.CostInvoiceStatusComments,
                        CurrentStatusStampDate: dashboardItem.CostInvoice.CostInvoiceStatusLastUpdateStampDate,
                        CurrentStatusStampFullName: dashboardItem.CostInvoice.CostInvoiceStatusLastUpdateStampFullName,
                        CurrentStatusType: Enum.EnumStatusType.CostInvoice,
                        EnumStatusType: Enum.EnumStatusType.CostInvoice,
                        EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.CostInvoice ,
                        IsEditable: true
                        };
                        $scope.refreshDocuments($scope.showAll,dashboardItem);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }

                $scope.searchChanged = () => {
                    $scope.searchText = $scope.searchText || '';

                    $scope.authorisationDashboard = _.filter($scope.authorisationDashboardOrginal, (n: interfaces.master.IAuthorisationDashBoard) => {
                        return (n.ReferenceNumber.toLocaleLowerCase().indexOf($scope.searchText.toLocaleLowerCase()) >= 0 || $scope.searchText.length === 0);
                    });
                }

                $scope.load();

                
            }


            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, statusService, salesOrderService, paymentService, purchaseOrderService, consignmentService,letterOfCreditService, documentRepositoryService, $uibModal, $state, bsLoadingOverlayService, $timeout, $q, $transitions,commercialInvoiceService
                ) => new authDashboardDirective(generalService, statusService, salesOrderService, paymentService, purchaseOrderService, consignmentService,letterOfCreditService, documentRepositoryService, $uibModal, $state, bsLoadingOverlayService, $timeout, $q, $transitions,commercialInvoiceService);
                directive.$inject = ["generalService", "statusService", "salesOrderService", "paymentService", "purchaseOrderService", "consignmentService","letterOfCreditService", "documentRepositoryService", "$uibModal", "$state", "bsLoadingOverlayService", '$timeout', '$q', '$transitions','commercialInvoiceService'];

                return directive;
            }

        }

        angular.module("app").directive("gtsAuthDashboard", authDashboardDirective.factory());
    }
}